$( document ).on('turbolinks:load', function() {
  // home slider
    $('.home-hero-2-right').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 600,
      dots: true,
      arrows: false
    });

    $('.discover-hero-2-right').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 600,
      dots: true,
      arrows: false
    });

    $('.discover-musicians').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 600,
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            infinite: true,
          }
        }
      ]
    });

  // ua slider
  $('.ua-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 600,
    fade: true,
    dots: true,
    arrows: false
  });

  // ua slider mobi
  $('.ua-slider-mob').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    speed: 600,
    fade: true,
    dots: true,
    arrows: false
  });
 
 
  // home into box height set
  var homeIntroBoxWidth = $(".home-intro-box").width();
  $(".home-intro-box").height(homeIntroBoxWidth);
  $(".home-intro-box-full").height(homeIntroBoxWidth);
  $(".home-intro-box-item").height(homeIntroBoxWidth);

  //home nav burger menu
  $( ".home-nav-burger-icon" ).click(function() {
    $(".home-nav-burger-menu").removeClass("d-none");
    $(".home-nav-burger-menu").addClass("d-flex");
  });
  $(".home-nav-burger-close").click(function() {
    $(".home-nav-burger-menu").removeClass("d-flex");
    $(".home-nav-burger-menu").addClass("d-none");
  });

  //destination nav burger menu
  $( ".destination-nav-burger-icon" ).click(function() {
    console.log('test')
    $(".destination-nav-burger-menu").removeClass("d-none");
    $(".destination-nav-burger-menu").addClass("d-flex");
  });
  $(".destination-nav-burger-close").click(function() {
    $(".destination-nav-burger-menu").removeClass("d-flex");
    $(".destination-nav-burger-menu").addClass("d-none");
  });

  //terms nav burger menu
  $( ".terms-nav-burger-icon" ).click(function() {
    console.log('test')
    $(".terms-nav-burger-menu").removeClass("d-none");
    $(".terms-nav-burger-menu").addClass("d-flex");
  });
  $(".terms-nav-burger-close").click(function() {
    $(".terms-nav-burger-menu").removeClass("d-flex");
    $(".terms-nav-burger-menu").addClass("d-none");
  });

  //discover nav burger menu
  $( ".discover-nav-burger-icon" ).click(function() {
    $(".discover-nav-burger-menu").removeClass("d-none");
    $(".discover-nav-burger-menu").addClass("d-flex");
  });
  $(".discover-nav-burger-close").click(function() {
    $(".discover-nav-burger-menu").removeClass("d-flex");
    $(".discover-nav-burger-menu").addClass("d-none");
  });
});
